import PhotoSwipe from "photoswipe";

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

import './plugins';

import tippy from 'tippy.js/dist/tippy.esm'
import $ from 'jquery';

import SingleMap from './modules/SingleMap';
import LoadMore from './modules/ReadMore';
import ProjectsNav from './modules/ProjectsNav';
import MainNav from './modules/MainNav';
import WorldMap from './modules/Worldmap';
import MediaSlider from './modules/MediaSlider';
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

// Needed for hot module reloading in webpack
if (module.hot) {
  module.hot.accept();
}


function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

function addBodyScrolled() {
  const scrollTop = $('html').scrollTop();
  if (scrollTop > 50) {
    $('body').addClass('scrolled');
  } else {
    $('body').removeClass('scrolled');
  }

  if (scrollTop > 90) {
    $('body').addClass('nav-scrolled');
  } else {
    $('body').removeClass('nav-scrolled');
  }
}

KBF.registerInitCallback('always', function () {
  setTimeout(function () {
    $('body').addClass('body-loaded');
  }, 1000)

  if (window.location.hash) scroll(0, 0);
// void some browsers issue
  if (window.location.hash) {

    let $el = $(window.location.hash);
    if ($el.length !== 1) return;
    // smooth scroll to the anchor id
    $('html, body').animate({
      scrollTop: $(window.location.hash).offset().top - 100 + 'px'
    }, 1000, 'swing');
  }

  $(document).foundation();

  var $homeslider = $('.gallery-slider').slick({
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true
  });

  setTimeout(function () {
    $('.wp-video').each(function (i, el) {
      var w = $(el).width();
      var h = $(el).height();
      if (w < h) {
        var $wrap = $(el).wrap('<div class="video-portrait"></div>').parents().last();
      }
    });
  }, 1)


  // var $quoteslider = $('.gallery-nav').slick({
  //   arrows: true,
  //   dots: true,
  //   pauseOnHover: true,
  //   slidesToShow: 4,
  //   asNavFor: '.gallery-slider',
  //   focusOnSelect: true
  // });

  var infoSliders = $('.info-slider');
  infoSliders.each(function (i, el) {
    $(el).on('init', function () {
      var elem = new Foundation.Equalizer($(el), {});
    })

    var sts = $(el).data('slides');
    $(el).slick({
      // appendArrows: '.slider-nav',
      // autoplay: true,
      // fade: true,
      dots: true,
      arrows: true,
      slidesToShow: sts || 4,
      autoplaySpeed: 5000,
      responsiveFirst: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        },
      ]

    });
  })

  $('.timeline-slider-wrap').on('init', function () {
    var elem = new Foundation.Equalizer($('[data-equalizer-man]'), {});
  });

  $('.timeline-slider-wrap').each(function (i, el) {
    var slides = $(el).data('slides');
    $(el).slick({
      arrows: true,
      dots: true,
      autoplay: false,
      fade: false,
      autoplaySpeed: 5000,
      slidesToShow: slides,
      slidesToScroll: 1,
      infinite: false,
      responsiveFirst: true,
      variableHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
    var firstSlick = $('.slick-active:first', el);
    var lastSlick = $('.slick-slide:last', el);

    $(firstSlick).addClass('first-slick');
    $(lastSlick).addClass('last-slick');
  })


  // var comp = new RegExp(location.host);
  // $('a').each(function () {
  //   if (comp.test($(this).attr('href'))) {
  //   } else {
  //     $(this).attr('target', '_blank');
  //   }
  // });

  $('li.external').each(function (i, el) {
    $(el).find('a').attr('target', '_blank');
  });
  document.querySelectorAll('.cocoen').forEach(function (element) {
    new Cocoen(element);
  });

  $('picture img[data-caption]').each(function (i, el) {
    if (el.dataset.caption !== '') {
      $(el).closest('picture').wrap('<div class="image-with-caption" data-caption="' + el.dataset.caption + '">');
    }
  });

  $('.caption-container[data-caption]').each(function (i, el) {
    if (el.dataset.caption !== '') {
      $('<span class="background-caption">' + el.dataset.caption + '</span>').appendTo($(el));
    }
  })

  var zstart = 100;

  tippy('.tippy', {
    arrow: true,
    interactive: true,
    maxWidth: '400px',
    trigger: 'click',
    hideOnClick: true,
    onShow: function (e) {
      $(e.reference).closest('.module').css('zIndex', zstart);

    },
    onShown: function (e, el) {
      zstart = zstart + 1;
      $(e.popper).find('.close-button').one('click', function () {
        e.hide();
      })

      var $map = $(e.popper).find('.mapcontainer');
      if ($map.length > 0 && !$map.hasClass('leaflet-container')) {
        new SingleMap({
          el: $map[0]
        })
      }

    },
    content: function (reference) {
      const id = reference.getAttribute('data-template');
      const template = document.getElementById(id);
      return template.innerHTML;
    },
  });

  $('.tippy').closest('.module').css('zIndex', zstart--);


  // var $map = $('.map-wrapper');
  // if ($map.length > 0) {
  //   new Map({
  //     el: $map.get(0)
  //   })
  // }

  $('.mainnav').on('show.zf.dropdownmenu', function () {
    $('body').addClass('dropdown-active');
  })

  $('.mainnav').on('hide.zf.dropdownmenu', function () {
    $('body').removeClass('dropdown-active');

  })


  $('.count-up').countTo();


  let timeout;


  $(window).on('scroll', function () {
    window.clearTimeout(timeout);

    timeout = window.setTimeout(function () {
      addBodyScrolled();
    }, 100);

  })

  $('[data-worldmap]').each(function (index, el) {
    var MV = new WorldMap({
      el: el,
    })
    MV.render();
  });

  $('[data-mainnav]').each(function (index, el) {
    var MV = new MainNav({
      el: el,
    })
    MV.render();
  });

  $('[data-media-slider]').each(function (index, el) {
    var MS = new MediaSlider({
      el: el,
    })
    MS.render();
  });

  $('.menu-toggle').on('click', function () {
    $('body').toggleClass('mobile-nav-open');
  });

  // Hack to fix issue where submenu links
  // on iPad require two clicks to open page, because
  // first click triggers mouseenter
  $('.sub-menu a').on('mouseenter', function () {
    event.stopPropagation();
  }).on('touchstart', function () {
    let href = this.href;

    $(this).trigger('mouseleave');
    window.setTimeout(function () {
      window.location.href = href;
    }, 300)
  });

  $('body').on('click', '.lightbox-item', function (e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this);
    var id = $this.data('galleryid');
    var index = $this.data('index');

    var pswpElement = $('.pswp')[0];
    var options = {
      galleryUID: id,
      bgOpacity: 0.99,
      index: index,
      shareEl: false,
      history: false
    };
    var referenceItems = window[id];
    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, referenceItems, options);
    gallery.init();

  });

  $('body').on('click', '[data-gallery]', function (e) {
    e.stopPropagation();
    e.preventDefault();
    var $this = $(this);
    var id = $this.data('gallery');
    var index = $this.data('index');

    var pswpElement = $('.pswp')[0];
    var options = {
      galleryUID: id,
      bgOpacity: 1,
      index: index,
      shareEl: false,
      history: false
    };
    var referenceItems = window[id];
    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, referenceItems, options);
    gallery.init();
  });

  $('.toggle-text').each(function (i, el) {
    var $container = $(this);
    var $trigger = $('[data-expand-trigger]', $container);
    var $target = $('[data-expand-target]', $container);
    $trigger.on('click', function () {
      $target.slideToggle(350);
      $trigger.hide();
    })

  })

  jQuery('[data-map]').each(function (i, el) {
    var map;
    var $map = jQuery(el);
    if ($map.length === 0) {
      return;
    }

    var lat = $map.data('lat');
    var lng = $map.data('lng');
    map = new google.maps.Map(el, {
      center: {lat: lat, lng: lng},
      zoom: 14
    });
    new google.maps.Marker({position: {lat: lat, lng: lng}, map: map})
  })

  if (navigator.share) {
    $('.social-icon.share').show();
  } else {
    $('.social-icon.share').hide();
  }

  $('.social-icon.share').on('click', function () {
    if (navigator.share) {
      if (navigator.share) {
        let url = document.location.href;
        const canonicalElement = document.querySelector('link[rel=canonical]');
        if (canonicalElement !== null) {
          url = canonicalElement.href;
        }
        navigator.share({url: url});
        navigator.share({
          title: document.title,
          text: 'Was für ein Staunen.',
          url: url,
        });
      }
    }
  })

  $('.reveal').on('open.zf.reveal', function () {
    var that = this;
    setTimeout(function (){
      window.dispatchEvent(new Event('resize'));
    },10)
  })

});
