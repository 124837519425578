var popup = require('../templates/popup.hbs');
export default Backbone.View.extend({
  initialize: function () {
    var that = this;
    this.markers = window.KB.appData.map.markers;
  },
  render: function () {
    var that = this;
    var mapOptions = {
      center: new google.maps.LatLng(0, 0),
      zoom: 2,
      minZoom: 1,
      mapTypeId: 'satellite'
    };
    this.map = new google.maps.Map(this.el,
      mapOptions);

    this.renderMarkers();
  },
  renderMarkers: function () {
    let that = this;
    this.markers.map(function (m) {
      console.log(m);
      let marker = new google.maps.Marker({position: {lat: parseInt(m.lat, 10), lng: parseInt(m.lng, 10)}, map: that.map})
      const infowindow = new google.maps.InfoWindow({
        content: popup(m),
      });


      marker.addListener("click", () => {
        if (that.currentWindow){
          that.currentWindow.close();
        }


        that.currentWindow = infowindow;
        infowindow.open({
          anchor: marker,
          map: that.map,
          shouldFocus: false,
        });
      });
    })
  }

});